import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import '../utils/normalize.css';
import '../utils/css/screen.css';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cookies: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const siteTitle = data.site.siteMetadata.title;
      return (
        <Layout title={siteTitle}>
          <SEO title="Cookies" keywords={[`cookies`, `gdpr`]} />
          <div className="section-page-tags section-page-general wrap">
            <h2 id="this-is-a-section">Política de cookies</h2>
            <h3>¿Qué son las Cookies?</h3>
            Una cookie es un fichero que se descarga en su ordenador al acceder del Usuario a determinadas páginas web.
            Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los
            hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la
            forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. Las Cookies, en función de
            quién gestiona el equipo o dominio desde los que se envían, pueden dividirse en Cookies propias (gestionadas
            por un equipo o dominio gestionado por el propio editor del sitio web) o Cookies de terceros (gestionados
            por terceras entidades que tratan los datos obtenidos a través de éstas).
            <hr></hr>
            <h3>¿Qué tipos de Cookies utiliza este sitio y cuáles son sus finalidades? </h3>
            <ol>
              <li>
                Cookies de sesión: Son un tipo de Cookies diseñadas y utilizadas para recabar y almacenar datos mientras
                el USUARIO accede a una página web. Como su propio nombre indica, estas Cookies se almacenan en el
                terminal hasta que finalice la sesión de navegación del USUARIO.
              </li>
              <li>
                Cookies de análisis: Son aquéllas que bien tratadas por nosotros o por terceros, nos permiten
                cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización
                que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web
                con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
              </li>
              <li>
                Cookies publicitarias: Son aquellas que, bien tratadas por nosotros o por terceros, nos permiten
                gestionar de la forma más eficaz posible la oferta de los espacios publicitarios que hay en la página
                web, adecuando el contenido del anuncio al contenido del servicio solicitado o al uso que realice de
                nuestra página web. Para ello podemos analizar sus hábitos de navegación en Internet y podemos mostrarle
                publicidad relacionada con su perfil de navegación.
              </li>
              <li>
                Cookies de publicidad comportamental: Se trata de Cookies diseñadas y utilizadas para la gestión, de la
                forma más eficaz posible, de los espacios publicitarios que, en su caso, se hayan incluido en la página
                web, aplicación o plataforma desde la que presta el servicio solicitado. Estas Cookies almacenan
                información del comportamiento de los usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en
                función del mismo. Cookies de redes sociales: Se trata de Cookies diseñadas y utilizadas para ofrecer al
                USUARIO la posibilidad de compartir o recomendar contenidos de nuestra página web en las redes sociales.
              </li>
            </ol>
            <h3>Política de cookies</h3>
            De conformidad con lo establecido en el apartado segundo del artículo 22 de la Ley 34/2002 de 11 de julio de
            Servicios de la Sociedad de la Información y comercio electrónico SiChef.app le informa que:
            <ol>
              <li>
                Las cookies constituyen una herramienta empleada por los servidores web para almacenar y recuperar
                información acerca de sus visitantes.
              </li>
              <li>
                SiChef emplea las siguientes cookies:
                <h4>SiChef</h4>
                <ul>
                  <li>Nombre de Cookies: gatsby-gdpr-google-analytics</li>
                  <li>Web del emisor: sichef.app</li>
                  <li>Tipo de Cookie: Cookie de sesión</li>
                  <li>Descripción: Cookie empleada para almacenar las preferencias de uso de cookies del usuario</li>
                </ul>
                <h4>Google Analytics</h4>
                <ul>
                  <li>Nombre de Cookies: _ga, _gat, _gid</li>
                  <li>Web del emisor: google.com</li>
                  <li>Tipo de Cookie: Cookie de análisis</li>
                  <li>Descripción: Análisis de tráfico en la web</li>
                </ul>
                <h4>Disqus</h4>
                <ul>
                  <li>Nombre de Cookies: csrftoken, disqusauth, disqusauths, sessionid</li>
                  <li>Web del emisor: disqus.com</li>
                  <li>Tipo de Cookie: Cookie de sesión</li>
                  <li>Descripción: Necesarias para que los usuarios puedan comentar en la página web.</li>
                </ul>
                <h4>Viglink</h4>
                <ul>
                  <li>Nombre de Cookies: vglnk.Agent.p</li>
                  <li>Web del emisor: viglink.com</li>
                  <li>Tipo de Cookie: Cookie de sesión</li>
                  <li>Descripción: Cookie necesaria para la utilización de las opciones y servicios del sitio web </li>
                </ul>
              </li>
              <li>Revocación y eliminación de cookies (por tipo de navegador).</li>
            </ol>
            <h3>Información sobre como bloquear el uso de cookies en diferentes navegadores</h3>
            <ol>
              <li>
                Para más información sobre como bloquear el uso de la cookies a través del navegador{' '}
                <a href="https://support.google.com/chrome/answer/95647?hl=es">Chrome en Soporte Chrome</a>
              </li>
              <li>
                Para más información sobre como bloquear el uso de la cookies a través del navegador{' '}
                <a href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">
                  Firefox en Soporte mozilla
                </a>
              </li>
              <li>
                Para más información sobre como bloquear el uso de la cookies a través del navegador{' '}
                <a href="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">
                  Internet Explorer en Soporte internet explorer
                </a>
              </li>
              <li>
                Para más información sobre como bloquear el uso de la cookies a través del navegador{' '}
                <a href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">Safari en Soporte Safari</a>
              </li>
            </ol>
            Además en tu navegador puedes activar el modo incógnito o privado, para borrar las cookies y el historial de
            navegación una vez termine tu sesión de navegación. Consulta la ayuda de tu navegador para acceder a este
            modo
          </div>
        </Layout>
      );
    }}
  />
);

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    benchAccounting: file(relativePath: { eq: "bench-accounting-49909-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Cookies;
